import React, { createContext, useState, useEffect, useContext } from "react";
import { MatrixClientContext } from "./MatrixClientContext";
import { AppConnectionContext } from "./AppConnectionContext";
import { RoomsListContext } from "./RoomsListContext";
import { RoomsDataContext } from "./RoomsDataContext";
import { RoomsStateContext } from "./RoomsStateContext";

export const AppStateContext = createContext();

const AppStateProvider = ({ children }) => {
  const { matrixClient } = useContext(MatrixClientContext);
  const { isAuthenticated, credentials, setIsAuthenticated } =
    useContext(AppConnectionContext);
  const { initializeRoomsList } = useContext(RoomsListContext);
  const { initializeRoomsData } = useContext(RoomsDataContext);
  const { initRoomsState } = useContext(RoomsStateContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeAppState = async () => {
      if (!matrixClient) {
        setIsLoading(false);
        return;
      }

      try {
        if (credentials && matrixClient) {
          await initializeRoomsList();
          await initializeRoomsData();
          await initRoomsState();
          setIsAuthenticated(true);
        } else {
          console.warn("Credentials are missing or invalid.");
        }
      } catch (error) {
        console.error("App state initialization failed:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeAppState();
  }, [credentials, isAuthenticated, matrixClient]);

  return (
    <AppStateContext.Provider
      value={{
        isLoading,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
