// FILE: src/contexts/MatrixClientContext.jsx
import React, { createContext, useContext, useEffect, useState } from "react";

import {
  createMatrixClient,
  reconnectMatrixClient,
  logoutMatrixClient,
} from "../../util/matrixService";
import {
  secureSetItem,
  secureGetItem,
  secureRemoveItem,
} from "../../util/secureStore";

/**
 * This context manages the Matrix client lifecycle:
 *  - create/connect
 *  - reconnect on foreground
 *  - logout/cleanup
 *  - store & retrieve matrix credentials in secure storage
 */

const MatrixClientContext = createContext();

export const MatrixClientProvider = ({ children }) => {
  const [matrixClient, setMatrixClient] = useState(null);

  // 'uninitialized' | 'connecting' | 'connected' | 'error'
  const [matrixStatus, setMatrixStatus] = useState("uninitialized");

  useEffect(() => {
    /**
     * On mount, check if we already have matrix credentials stored
     * If so, create the matrix client.
     */
    const initMatrix = async () => {
      console.log("TEST: Checking secure store for matrix credentials...");
      setMatrixStatus("connecting");

      const rawCreds = await secureGetItem("matrixCredentials");
      if (!rawCreds) {
        console.warn("TEST: No matrix creds found => not connecting");
        setMatrixStatus("error");
        return;
      }

      const credentials = JSON.parse(rawCreds);

      try {
        const client = await createMatrixClient(credentials);
        setMatrixClient(client);
        setMatrixStatus("connected");
        console.log("TEST: Matrix client is connected!");
      } catch (err) {
        console.error("TEST: Error creating matrix client =>", err);
        setMatrixStatus("error");
      }
    };

    initMatrix();

    // Listen for background->foreground "matrix:refresh" triggers
    const handleRefresh = () => {
      console.log("TEST: Received matrix:refresh event");
      if (matrixClient) {
        reconnectMatrixClient(matrixClient);
      }
    };
    document.addEventListener("matrix:refresh", handleRefresh);

    return () => {
      document.removeEventListener("matrix:refresh", handleRefresh);
    };
  }, [matrixClient]);

  /**
   * Log out of Matrix entirely, remove local credentials,
   * and reset matrix client state.
   */
  const logout = async () => {
    console.log("TEST: Logging out from matrix context");
    if (matrixClient) {
      await logoutMatrixClient(matrixClient);
    }
    setMatrixClient(null);
    await secureRemoveItem("matrixCredentials");
    setMatrixStatus("uninitialized");
  };

  /**
   * If we just obtained matrix credentials (say, after an OAuth exchange),
   * we call this to fully init the client.
   */
  const loginWithMatrixCredentials = async (credentials) => {
    console.log("TEST: [loginWithMatrixCredentials] =>", credentials);
    try {
      // Clean any old data
      await secureRemoveItem("matrixCredentials");
      // Save new credentials
      await secureSetItem("matrixCredentials", JSON.stringify(credentials));

      setMatrixStatus("connecting");
      const client = await createMatrixClient(credentials);
      setMatrixClient(client);
      setMatrixStatus("connected");
      console.log("TEST: Matrix client connected after new creds!");
    } catch (err) {
      console.error("TEST: loginWithMatrixCredentials error =>", err);
      setMatrixStatus("error");
    }
  };

  return (
    <MatrixClientContext.Provider
      value={{
        matrixClient,
        matrixStatus,
        logout,
        loginWithMatrixCredentials,
      }}
    >
      {children}
    </MatrixClientContext.Provider>
  );
};

export const useMatrixClient = () => useContext(MatrixClientContext);
export { MatrixClientContext }; // <- Add this line
