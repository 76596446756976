import * as sdk from "matrix-js-sdk";
import Olm from "@matrix-org/olm";

global.Olm = Olm;

const waitForPreparedState = (
  matrixClient,
  maxRetries = 50,
  retryInterval = 100
) => {
  return new Promise((resolve, reject) => {
    let initialSyncComplete = false;
    let retryCount = 0;

    const handleSync = (state, prevState, data) => {
      if (state === "PREPARED") {
        matrixClient.removeListener("sync", handleSync);
        resolve();
      } else if (
        state === "SYNCING" &&
        initialSyncComplete &&
        data &&
        data.catchingUp === false
      ) {
        matrixClient.removeListener("sync", handleSync);
        resolve();
      } else if (state === "ERROR" || state === null || state === "STOPPED") {
        retryCount++;
        if (retryCount <= maxRetries) {
          setTimeout(() => {
            matrixClient.startClient({ lazyLoadMembers: true });
          }, retryInterval);
        } else {
          console.error("Max retries reached. Rejecting sync.");
          matrixClient.removeListener("sync", handleSync);
          reject(new Error(`Matrix sync failed after ${maxRetries} retries.`));
        }
      }
    };

    matrixClient.on("sync", handleSync);
  });
};

export const createMatrixClient = async (credentials) => {
  if (
    !credentials ||
    !credentials.baseUrl ||
    !credentials.accessToken ||
    !credentials.userId
  ) {
    return null;
  }

  const indexedDBStore = new sdk.IndexedDBStore({
    indexedDB: global.indexedDB,
    localStorage: global.localStorage,
    dbName: "web-sync-store",
  });

  await indexedDBStore.startup();

  const matrixClient = sdk.createClient({
    baseUrl: credentials.baseUrl,
    accessToken: credentials.accessToken,
    userId: credentials.userId,
    store: indexedDBStore,
    cryptoStore: new sdk.IndexedDBCryptoStore(global.indexedDB, "crypto-store"),
    deviceId: credentials.deviceId,
    timelineSupport: true,
    verificationMethods: ["m.sas.v1"],
  });

  //   await this.matrixClient.initCrypto();
  await matrixClient.startClient({ lazyLoadMembers: true });
  await waitForPreparedState(matrixClient);
  return matrixClient;
};

export const reconnectMatrixClient = async (matrixClient) => {
  if (!matrixClient) {
    console.error("Matrix client is not initialized. Skipping reconnect.");
    return;
  }

  try {
    const syncState = matrixClient.getSyncState();
    if (syncState === null || syncState === "STOPPED") {
      await matrixClient.startClient({ lazyLoadMembers: true });
      await waitForPreparedState(matrixClient);
    } else {
      await matrixClient.startClient({ lazyLoadMembers: true });
    }
  } catch (error) {
    console.error("Error while reconnecting Matrix client:", error);
  }
};

export const logoutMatrixClient = async (matrixClient) => {
  if (!matrixClient) {
    console.error("Matrix client is not initialized. Skipping logout.");
    return;
  }

  try {
    matrixClient.stopClient();
    await matrixClient.logout();
    await matrixClient.clearStores();
    const matrixKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith("mxjssdk_")
    );
    matrixKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
  } catch (error) {
    console.error("Error during Matrix client logout:", error);
  }
};
