import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";

const EXPECTED_VERSIONS = {
  ios: "2.0.6",
  android: "3.0.3",
};

const useAppVersionCheck = () => {
  useEffect(() => {
    const checkAppVersion = () => {
      const platform = Capacitor.getPlatform();

      // Skip the check for web
      if (platform === "web") return;

      const expectedVersion = EXPECTED_VERSIONS[platform];

      // Get stored version (default to empty string if nothing is set)
      const storedVersion = localStorage.getItem("app_version") || "";

      // If no version is stored OR it doesn’t match, force update
      if (!storedVersion || storedVersion !== expectedVersion) {
        localStorage.setItem("app_version", expectedVersion);

        alert(`A new update is available! Restarting the app...`);
        window.location.reload(); // Refresh to load the latest update
      }
    };

    checkAppVersion();
  }, []);
};

export default useAppVersionCheck;
