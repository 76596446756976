import React, { createContext, useState, useEffect, useContext } from "react";
import { Capacitor } from "@capacitor/core";
import { useWalletClient } from "wagmi"; // ✅ Call useWalletClient here
import { getEthereumSigner } from "../../util/providers/ethereumProvider";
import { getSolanaSigner } from "../../util/providers/solanaProvider";

const CHAIN_NETWORK_ENV = {
  ethereum: "VITE_DEFAULT_ETH_NETWORK",
  solana: "VITE_DEFAULT_SOLANA_NETWORK",
  polygon: "VITE_DEFAULT_POLYGON_NETWORK",
  avalanche: "VITE_DEFAULT_AVALANCHE_NETWORK",
};

// 🛠 Dynamically set this in future
const DEFAULT_CHAIN = "ethereum";
const DEFAULT_NETWORK = import.meta.env[CHAIN_NETWORK_ENV[DEFAULT_CHAIN]];

const BlockchainContext = createContext();

const BlockchainProvider = ({ children }) => {
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null);
  const [chain, setChain] = useState(DEFAULT_CHAIN);
  const [network, setNetwork] = useState(DEFAULT_NETWORK);
  const [privateKey, setPrivateKey] = useState(null);
  const [mnemonic, setMnemonic] = useState(null);
  const platform = Capacitor.getPlatform();
  const { data: walletClient } = useWalletClient(); 

  const getSigner = async () => {
    if (chain === "ethereum") {
      return await getEthereumSigner({
        platform,
        network,
        setPrivateKey,
        setMnemonic,
        walletClient, // ✅ Pass walletClient as a prop
      });
    } else if (chain === "solana") {
      return await getSolanaSigner({
        platform,
        network,
        setPrivateKey,
        setMnemonic,
      });
    } else {
      console.warn("Unsupported chain:", chain);
      return { signer: null, address: null };
    }
  };

  useEffect(() => {
    const initializeSigner = async () => {
      try {
        const { signer, address } = await getSigner();
        setSigner(signer);
        setAddress(address);
      } catch (error) {
        console.error("Error initializing signer:", error);
      }
    };

    initializeSigner();
  }, [chain, network, platform, walletClient]); // ✅ Added walletClient to deps

  return (
    <BlockchainContext.Provider
      value={{
        signer,
        address,
        chain,
        network,
        setChain,
        setNetwork,
        privateKey,
        mnemonic,
      }}
    >
      {children}
    </BlockchainContext.Provider>
  );
};

export const useBlockchainContext = () => useContext(BlockchainContext);
export default BlockchainProvider;
