import React, { createContext, useState, useEffect } from "react";
import { App } from "@capacitor/app";

export const AppGlobalStateContext = createContext();

const AppGlobalStateProvider = ({ children }) => {
  const [isAppActive, setIsAppActive] = useState(true);

  useEffect(() => {
    const handleAppStateChange = ({ isActive }) => {
      setIsAppActive(isActive);
    };

    App.addListener("appStateChange", handleAppStateChange);

    return () => {
      App.removeListener("appStateChange", handleAppStateChange);
    };
  }, []);

  return (
    <AppGlobalStateContext.Provider value={{ isAppActive }}>
      {children}
    </AppGlobalStateContext.Provider>
  );
};

export default AppGlobalStateProvider;
