import React, { createContext, useContext, useState, useEffect } from "react";

import waitForRoom from "../../util/waitForRoom";
import { MatrixClientContext } from "./MatrixClientContext";

const RoomSelectContext = createContext();

export const useRoomSelect = () => useContext(RoomSelectContext);

export const RoomSelectProvider = ({ children }) => {
  const [roomId, setRoomId] = useState(null);
  const [dmRoomId, setDmRoomId] = useState(null);
  const [spaceId, setSpaceId] = useState(null);
  const [space, setSpace] = useState(null);
  const [currentTab, setCurrentTab] = useState("SPACES");

  const { matrixClient } = useContext(MatrixClientContext);

  useEffect(() => {
    if (spaceId !== null) {
      waitForRoom(spaceId, matrixClient).then((room) => {
        if (!room) {
          setSpaceId(null);
          setSpace(null);
        } else {
          setSpace(room);
        }
      });
    }
  }, [spaceId, matrixClient]);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <RoomSelectContext.Provider
      value={{
        roomId,
        setRoomId,
        dmRoomId,
        setDmRoomId,
        spaceId,
        setSpaceId,
        space,
        currentTab,
        setCurrentTab,
        handleTabChange,
      }}
    >
      {children}
    </RoomSelectContext.Provider>
  );
};
