// FILE: src/App.jsx
import React, { useEffect, useState } from "react";
import { App as CapacitorApp } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { secureGetItem } from "./util/secureStore";
import useAppVersionCheck from "./app/hooks/useAppVersionCheck";
import LoginScreen from "./app/components/login/LoginScreen";
import { Providers } from "./app/contexts/Providers";

export default function App() {
  const [isReady, setIsReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useAppVersionCheck();

  useEffect(() => {
    const init = async () => {
      console.log(
        "TEST: App init => checking if user is already logged in (secure store)."
      );

      // Check if user info is stored (if so, they're 'authenticated' in our simple logic)
      const storedUser = await secureGetItem("user");
      const isLoggedIn = !!storedUser;
      setIsAuthenticated(isLoggedIn);

      console.log("TEST: Hiding splash screen...");
      await SplashScreen.hide();
      setIsReady(true);
    };

    init();

    // Listen for background <-> foreground transitions
    CapacitorApp.addListener("appStateChange", (state) => {
      console.log("TEST: appStateChange =>", state);
      if (state.isActive) {
        // Fire an event to refresh the matrix client if needed
        console.log("TEST: App is active => dispatch matrix:refresh event");
        document.dispatchEvent(new CustomEvent("matrix:refresh"));
      }
    });
  }, []);

  if (!isReady) {
    // While not ready, presumably the splash screen is showing
    return null;
  }

  // If user is NOT authenticated, show the Login screen
  if (!isAuthenticated) {
    return <LoginScreen onLoginSuccess={setIsAuthenticated} />;
  }

  // If user is authenticated, wrap the main app in all providers (Matrix, etc.)
  return (
    <Providers>
      <div className="p-4 text-center">
        <h1 className="text-xl font-bold">
          Welcome to Waivlength (Secure Storage Edition)!
        </h1>
        <p>Here you can gate final UI based on Matrix sync status, etc.</p>
      </div>
    </Providers>
  );
}
