// FILE: src/services/matrixService.js
import * as sdk from "matrix-js-sdk";
import Olm from "@matrix-org/olm";

/**
 * matrixService => handles raw Matrix client creation, reconnection, and logout.
 * You provided most of this logic; we've just added some TEST logs.
 */

global.Olm = Olm; // Ensure Olm is globally available

const waitForPreparedState = (
  matrixClient,
  maxRetries = 50,
  retryInterval = 100
) => {
  return new Promise((resolve, reject) => {
    let initialSyncComplete = false;
    let retryCount = 0;

    const handleSync = (state, prevState, data) => {
      console.log(`TEST: [waitForPreparedState] sync event => state: ${state}`);

      if (state === "PREPARED") {
        console.log("TEST: Matrix sync => PREPARED");
        matrixClient.removeListener("sync", handleSync);
        resolve();
      } else if (
        state === "SYNCING" &&
        initialSyncComplete &&
        data &&
        data.catchingUp === false
      ) {
        console.log("TEST: Matrix sync => SYNCING done");
        matrixClient.removeListener("sync", handleSync);
        resolve();
      } else if (state === "CATCHUP") {
        // This can happen after background => foreground
        initialSyncComplete = true;
      } else if (state === "ERROR" || state === null || state === "STOPPED") {
        console.error("TEST: Matrix sync error or stopped, will retry...");
        retryCount++;
        if (retryCount <= maxRetries) {
          setTimeout(() => {
            matrixClient.startClient({ lazyLoadMembers: true });
          }, retryInterval);
        } else {
          console.error("TEST: Max retries reached. Rejecting sync.");
          matrixClient.removeListener("sync", handleSync);
          reject(new Error(`Matrix sync failed after ${maxRetries} retries.`));
        }
      }
    };

    matrixClient.on("sync", handleSync);
  });
};

export const createMatrixClient = async (credentials) => {
  if (
    !credentials ||
    !credentials.baseUrl ||
    !credentials.accessToken ||
    !credentials.userId
  ) {
    console.error("TEST: Missing matrix credentials, cannot create client.");
    return null;
  }

  console.log(
    "TEST: Starting matrix client creation with credentials:",
    credentials
  );

  const indexedDBStore = new sdk.IndexedDBStore({
    indexedDB: global.indexedDB,
    localStorage: global.localStorage,
    dbName: "web-sync-store",
  });

  await indexedDBStore.startup();

  const matrixClient = sdk.createClient({
    baseUrl: credentials.baseUrl,
    accessToken: credentials.accessToken,
    userId: credentials.userId,
    store: indexedDBStore,
    cryptoStore: new sdk.IndexedDBCryptoStore(global.indexedDB, "crypto-store"),
    deviceId: credentials.deviceId,
    timelineSupport: true,
    verificationMethods: ["m.sas.v1"],
  });

  // Start the client & wait for PREPARED or SYNCING done
  await matrixClient.startClient({ lazyLoadMembers: true });
  await waitForPreparedState(matrixClient);

  console.log("TEST: Matrix client is fully PREPARED & synced.");
  return matrixClient;
};

export const reconnectMatrixClient = async (matrixClient) => {
  if (!matrixClient) {
    console.error("TEST: reconnectMatrixClient => matrixClient is null!");
    return;
  }

  try {
    const syncState = matrixClient.getSyncState();
    console.log("TEST: Current sync state =>", syncState);

    if (syncState === null || syncState === "STOPPED") {
      console.log("TEST: Trying to restart client...");
      await matrixClient.startClient({ lazyLoadMembers: true });
      await waitForPreparedState(matrixClient);
    } else {
      // If it's already running, just ensure it's started
      await matrixClient.startClient({ lazyLoadMembers: true });
    }
    console.log("TEST: Matrix client reconnected / started again.");
  } catch (error) {
    console.error("TEST: Error while reconnecting Matrix client:", error);
  }
};

export const logoutMatrixClient = async (matrixClient) => {
  if (!matrixClient) {
    console.error("TEST: logoutMatrixClient => matrixClient is null!");
    return;
  }

  try {
    console.log("TEST: Stopping matrix client & clearing data...");
    matrixClient.stopClient();
    await matrixClient.logout();
    await matrixClient.clearStores();

    // Clean up localStorage keys from matrix
    const matrixKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith("mxjssdk_")
    );
    matrixKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
    console.log("TEST: Matrix logout complete.");
  } catch (error) {
    console.error("TEST: Error during Matrix client logout:", error);
  }
};
