import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { MatrixClientContext } from "./MatrixClientContext";
import { RoomsListContext } from "./RoomsListContext";
import { NotificationsContext } from "./NotificationsContext";
import { getAuthenticatedMediaUrl } from "../../util/getAuthenticatedMediaUrl";
import { formatLatestEvent } from "../../util/formatLatestEvent"; // NEW IMPORT

export const RoomsDataContext = createContext();

const RoomsDataProvider = ({ children }) => {
  const { matrixClient } = useContext(MatrixClientContext);
  const { roomsList } = useContext(RoomsListContext);
  const { getTotalNoti } = useContext(NotificationsContext);
  const [chatList, setChatList] = useState([]);
  const [spaceList, setSpaceList] = useState([]);

  const initializeRoomsData = async () => {
    if (!matrixClient || !roomsList) {
      return;
    }

    updateChatList();
    updateSpaceList();
  };

  const getLatestMessageEvent = (room) => {
    const events = room.timeline;

    for (let i = events.length - 1; i >= 0; i--) {
      const event = events[i];
      const isRedacted = !!event.event.unsigned?.redacted_because;
      const isEditedMessageFallback = event.getContent()?.body?.startsWith("*");

      if (isRedacted || isEditedMessageFallback) {
        continue;
      }

      return event;
    }

    return null;
  };

  const createChatData = useCallback(
    (room) => {
      const roomId = room.roomId;
      const latestEvent = getLatestMessageEvent(room);
      const isDM = roomsList.directMessages.has(roomId);
      const isRoom = roomsList.rooms.has(roomId);

      const avatarEvent = room?.currentState.getStateEvents(
        "m.room.avatar",
        ""
      );
      let avatarUrl = avatarEvent
        ? getAuthenticatedMediaUrl(matrixClient, avatarEvent.getContent().url)
        : null;

      let user = null;
      if (isDM && room.summaryHeroes && room.summaryHeroes.length > 0) {
        const userId = room.summaryHeroes[0];
        user = matrixClient.getUser(userId);
      }

      if (isDM && !avatarUrl) {
        const fallbackMember = room.getAvatarFallbackMember();
        if (fallbackMember) {
          avatarUrl = fallbackMember.getAvatarUrl(
            matrixClient.baseUrl,
            40,
            40,
            "crop"
          );
        }
      }

      let latestMessage = latestEvent
        ? formatLatestEvent(latestEvent, matrixClient)
        : null;

      if (isDM && !user) return null;

      return {
        id: roomId,
        name: room.name,
        displayName: isDM && user ? user.displayName : room.name,
        avatar: avatarUrl,
        user: user,
        latestMessage: latestMessage,
        unreadCount: getTotalNoti(roomId),
        isDM: isDM,
        isRoom: isRoom,
      };
    },
    [matrixClient, getTotalNoti, roomsList]
  );

  const createSpaceData = useCallback(
    (spaceRoom) => {
      const roomId = spaceRoom.roomId;
      const avatarEvent = spaceRoom?.currentState.getStateEvents(
        "m.room.avatar",
        ""
      );
      const avatarUrl = avatarEvent
        ? getAuthenticatedMediaUrl(matrixClient, avatarEvent.getContent().url)
        : null;

      const unreadCount = getTotalNoti(roomId);
      const membersMap = spaceRoom.currentState?.members;
      let membersArray = [];
      if (membersMap) {
        if (typeof membersMap.values === "function") {
          membersArray = Array.from(membersMap.values());
        } else {
          membersArray = Object.values(membersMap);
        }
      }
      const firstFiveMembers = membersArray.slice(0, 5);
      const joinedMemberCount = spaceRoom.getJoinedMemberCount();
      let tokenSymbol = null;
      const tokenDataEvent = spaceRoom.currentState?.getStateEvents(
        "custom.token_data",
        ""
      );
      if (tokenDataEvent) {
        const tokenData = tokenDataEvent.getContent();
        tokenSymbol = tokenData?.tokenSymbol || null;
      }
      let roomTopic = null;
      const topicEvent = spaceRoom.currentState?.getStateEvents(
        "m.room.topic",
        ""
      );
      if (topicEvent) {
        const topicContent = topicEvent.getContent();
        roomTopic = topicContent?.topic || null;
      }
      const enhancedMembers = firstFiveMembers.map((member) => {
        let memberAvatarUrl = null;
        if (member.getAvatarUrl) {
          memberAvatarUrl = member.getAvatarUrl(
            matrixClient.baseUrl,
            40,
            40,
            "crop"
          );
        } else if (member.avatarUrl) {
          memberAvatarUrl = member.avatarUrl;
        }
        return {
          userId: member.userId,
          avatarUrl: memberAvatarUrl,
        };
      });

      return {
        id: roomId,
        name: spaceRoom.name,
        avatar: avatarUrl,
        unreadCount: unreadCount,
        members: enhancedMembers,
        joinedMemberCount: joinedMemberCount,
        tokenSymbol: tokenSymbol,
        topic: roomTopic,
        lastActivityTs: spaceRoom.getLastActiveTimestamp(),
      };
    },
    [matrixClient, getTotalNoti]
  );

  const updateChatList = useCallback(() => {
    if (!roomsList || !matrixClient) return;

    const directs = Array.from(roomsList.directMessages);
    const rooms = Array.from(roomsList.rooms);
    const allRooms = [...directs, ...rooms]
      .map((roomId) => matrixClient.getRoom(roomId))
      .filter((room) => room != null);

    const filteredRooms = allRooms.filter((room) => {
      const isChild = room.currentState
        ?.getStateEvents("m.space.parent")
        ?.some((event) => !!event);
      return !isChild;
    });

    const allChatData = filteredRooms
      .map(createChatData)
      .filter((chatData) => chatData != null);
    setChatList(allChatData);
  }, [createChatData, matrixClient, roomsList]);

  const updateSpaceList = useCallback(() => {
    if (!roomsList || !matrixClient) return;

    const spaceIds = Array.from(roomsList.spaces);
    const spaceRooms = spaceIds
      .map((roomId) => matrixClient.getRoom(roomId))
      .filter((room) => room != null);

    const allSpaceData = spaceRooms.map(createSpaceData);
    const sortedSpaceData = allSpaceData.sort(
      (a, b) => b.lastActivityTs - a.lastActivityTs
    );
    setSpaceList(sortedSpaceData);
  }, [roomsList, matrixClient, createSpaceData]);

  // **Added refresh functions**
  const refreshChatList = useCallback(() => {
    updateChatList();
  }, [updateChatList]);

  const refreshSpaceList = useCallback(() => {
    updateSpaceList();
  }, [updateSpaceList]);

  useEffect(() => {
    updateChatList();
    updateSpaceList();
  }, [updateChatList, updateSpaceList, roomsList]);

  return (
    <RoomsDataContext.Provider
      value={{
        chatList,
        spaceList,
        initializeRoomsData,
        refreshChatList,
        refreshSpaceList,
      }}
    >
      {children}
    </RoomsDataContext.Provider>
  );
};

export default RoomsDataProvider;
