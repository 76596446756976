import React, { createContext, useContext, useState } from "react";
import { MatrixClientContext } from "./MatrixClientContext";
import RoomState from "../../client/state/RoomState";

export const RoomsStateContext = createContext();

export const RoomsStateProvider = ({ children }) => {
  const { matrixClient } = useContext(MatrixClientContext);
  const [roomStates, setRoomStates] = useState(new Map());

  const fetchUserRooms = async () => {
    try {
      const rooms = await matrixClient.getRooms();
      const nonSpaceRooms = rooms.filter((room) => !room.isSpaceRoom());
      return nonSpaceRooms.sort(
        (a, b) => b.getLastActiveTimestamp() - a.getLastActiveTimestamp()
      );
    } catch {
      return [];
    }
  };

  const initPriorityRooms = async (rooms) => {
    for (const room of rooms) {
      if (!roomStates.has(room.roomId)) {
        const roomState = new RoomState(matrixClient, room);
        try {
          await roomState.initialize();
          setRoomStates((prevRoomStates) => {
            const updatedRoomStates = new Map(prevRoomStates);
            updatedRoomStates.set(room.roomId, roomState);
            return updatedRoomStates;
          });
        } catch (error) {
          console.error("Failed to init priority room:", error);
        }
      }
    }
  };

  const initDeferredRooms = async (rooms, batchSize = 5) => {
    for (let i = 0; i < rooms.length; i += batchSize) {
      const batch = rooms.slice(i, i + batchSize);

      await Promise.all(
        batch.map(async (room) => {
          if (!roomStates.has(room.roomId)) {
            const roomState = new RoomState(matrixClient, room);
            try {
              await roomState.initialize();
              setRoomStates((prevRoomStates) => {
                const updatedRoomStates = new Map(prevRoomStates);
                updatedRoomStates.set(room.roomId, roomState);
                return updatedRoomStates;
              });
            } catch (err) {
              console.error("Failed to init deferred room:", err);
            }
          }
        })
      );
    }
  };

  const initRoomsState = async () => {
    try {
      const rooms = await fetchUserRooms();
      if (rooms && rooms.length > 0) {
        const priorityRooms = rooms.filter(
          (room) => room.getUnreadNotificationCount() > 0
        );
        const deferredRooms = rooms.filter(
          (room) => room.getUnreadNotificationCount() === 0
        );

        await initPriorityRooms(priorityRooms);
        initDeferredRooms(deferredRooms).catch(() => {});
      }
    } catch (error) {
      console.error("initRoomsState error:", error);
    }
  };

  /**
   * Create a new RoomState for a roomId if it does not exist yet.
   * Returns the existing or newly-initialized instance.
   */
  const initializeNewRoomState = async (roomId) => {
    if (roomStates.has(roomId)) {
      return roomStates.get(roomId);
    }

    const room = matrixClient.getRoom(roomId);
    if (!room) {
      throw new Error("Could not find this room in the matrix client.");
    }

    const roomState = new RoomState(matrixClient, room);
    try {
      await roomState.initialize();
      setRoomStates((prevRoomStates) => {
        const updatedRoomStates = new Map(prevRoomStates);
        updatedRoomStates.set(roomId, roomState);
        return updatedRoomStates;
      });
      return roomState;
    } catch (error) {
      console.error(
        `Failed to initialize new RoomState for room ${roomId}:`,
        error
      );
      throw error;
    }
  };

  const getRoomState = (roomId) => roomStates.get(roomId);

  const contextValue = {
    roomStates,
    initRoomsState,
    getRoomState,
    initializeNewRoomState,
  };

  return (
    <RoomsStateContext.Provider value={contextValue}>
      {children}
    </RoomsStateContext.Provider>
  );
};

export default RoomsStateProvider;
