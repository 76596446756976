// FILE: src/util/secureStore.js
import { Capacitor } from "@capacitor/core";
import SecureStorageIOS from "./SecureStorageIOS";
import SecureStorageAndroid from "./SecureStorageAndroid";

/**
 * A universal helper that saves/reads/removes items from
 *  - iOS secure storage
 *  - Android secure storage
 *  - localStorage fallback (Web/PWA)
 *
 * This ensures we avoid using Capacitor's Preferences plugin entirely.
 * We rely on actual secure storage for iOS/Android,
 * plus a best-effort fallback for web.
 */

export async function secureSetItem(key, value) {
  const platform = Capacitor.getPlatform();
  console.log("TEST: secureSetItem => platform:", platform);

  if (platform === "ios") {
    try {
      await SecureStorageIOS.setItem({ key, value });
      return;
    } catch (err) {
      console.error("TEST: iOS secureSetItem error =>", err);
      localStorage.setItem(key, value);
    }
  } else if (platform === "android") {
    try {
      await SecureStorageAndroid.setItem({ key, value });
      return;
    } catch (err) {
      console.error("TEST: Android secureSetItem error =>", err);
      localStorage.setItem(key, value);
    }
  } else {
    // Web or other platforms
    localStorage.setItem(key, value);
  }
}

export async function secureGetItem(key) {
  const platform = Capacitor.getPlatform();
  console.log("TEST: secureGetItem => platform:", platform);

  if (platform === "ios") {
    try {
      const val = await SecureStorageIOS.getItem({ key });
      if (val !== null && val !== undefined) return val;
    } catch (err) {
      console.error("TEST: iOS secureGetItem error =>", err);
    }
    return localStorage.getItem(key); // fallback
  } else if (platform === "android") {
    try {
      const val = await SecureStorageAndroid.getItem({ key });
      if (val !== null && val !== undefined) return val;
    } catch (err) {
      console.error("TEST: Android secureGetItem error =>", err);
    }
    return localStorage.getItem(key); // fallback
  } else {
    // Web
    return localStorage.getItem(key);
  }
}

export async function secureRemoveItem(key) {
  const platform = Capacitor.getPlatform();
  console.log("TEST: secureRemoveItem => platform:", platform);

  if (platform === "ios") {
    try {
      await SecureStorageIOS.removeItem({ key });
    } catch (err) {
      console.error("TEST: iOS secureRemoveItem error =>", err);
    }
    localStorage.removeItem(key);
  } else if (platform === "android") {
    try {
      await SecureStorageAndroid.removeItem({ key });
    } catch (err) {
      console.error("TEST: Android secureRemoveItem error =>", err);
    }
    localStorage.removeItem(key);
  } else {
    // Web
    localStorage.removeItem(key);
  }
}
