import { generateJWTToken } from "./jwtHandler";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

async function web3login(baseUrl, walletAddress, jwtSecret) {
  const lowercaseWalletAddress = walletAddress.toLowerCase();
  const generatedJwtToken = generateJWTToken(
    lowercaseWalletAddress,
    jwtSecret,
    baseUrl
  );

  const jsonResponse = await access(baseUrl, generatedJwtToken);
  const accessToken = jsonResponse.access_token;
  const deviceId = jsonResponse.device_id;
  const userId = jsonResponse.user_id;

  // Generate and set a random display name
  const displayName = generateDisplayName();
  await setDisplayName(baseUrl, userId, accessToken, displayName);

  return { accessToken, deviceId, userId };
}

async function access(baseUrl, generatedJwtToken) {
  const response = await fetch(`${baseUrl}/_matrix/client/r0/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      type: "org.matrix.login.jwt",
      token: generatedJwtToken,
      initial_device_display_name: "Waivlength App",
    }),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return jsonResponse;
}

function generateDisplayName() {
  return uniqueNamesGenerator({
    dictionaries: [colors, adjectives, animals],
    separator: "-",
    style: "lowerCase",
  });
}

async function setDisplayName(baseUrl, userId, accessToken, displayName) {
  const url = `${baseUrl}/_matrix/client/v3/profile/${encodeURIComponent(
    userId
  )}/displayname`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ displayname: displayName }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to set display name: ${errorText}`);
  }
}

export { web3login };
