import React from "react";
import { SocialLogin } from "@capgo/capacitor-social-login";
import { secureSetItem } from "../../../util/secureStore";
import { useMatrixClient } from "../../contexts/MatrixClientContext";
import { Capacitor } from "@capacitor/core";

/**
 * Platform-aware LoginScreen:
 * - iOS: shows Apple + Google
 * - Android/Web: shows Google only
 */
export default function LoginScreen({ onLoginSuccess }) {
  const { loginWithMatrixCredentials } = useMatrixClient();
  const platform = Capacitor.getPlatform(); // 'ios' | 'android' | 'web'
  const redirectUrl = import.meta.env.VITE_WAIVLENGTH_APPLE_REDIRECT_URL;

  const handleLogin = async (provider) => {
    console.log(`TEST: Starting ${provider} OAuth login on ${platform}`);

    try {
      // Base config shared across providers
      const config = {
        google: {
          webClientId: "YOUR_GOOGLE_WEB_CLIENT_ID",
          iOSClientId: "YOUR_GOOGLE_IOS_CLIENT_ID",
          iOSServerClientId: "YOUR_GOOGLE_IOS_SERVER_CLIENT_ID",
        },
        apple: {
          clientId: "app.waivlength.login", // Your Apple Service ID
          redirectUrl: redirectUrl,
        },
      };

      await SocialLogin.initialize({
        [provider]: config[provider],
      });

      const res = await SocialLogin.login({
        provider,
        options: {
          scopes: ["email", "name", "profile"],
        },
      });

      console.log("TEST: OAuth response =>", res.result);

      const profile = res.result.profile;
      const userId = provider === "google" ? profile.id : profile.user;
      const displayName =
        provider === "apple"
          ? `${profile.givenName ?? ""} ${profile.familyName ?? ""}`.trim()
          : profile.name ?? "";

      await secureSetItem(
        "user",
        JSON.stringify({
          provider,
          userId,
          email: profile.email,
          name: displayName,
        })
      );
      console.log("TEST: Stored user info in secure store => 'user' key.");

      // Mock matrix credentials for now
      const mockMatrixCreds = {
        baseUrl: "https://matrix.example.com",
        accessToken: "FAKE_ACCESS_TOKEN",
        userId: "@fake:example.com",
        deviceId: "FAKE_DEVICE_ID",
      };

      await loginWithMatrixCredentials(mockMatrixCreds);
      console.log("TEST: OAuth + Matrix login complete");
      onLoginSuccess(true);
    } catch (err) {
      console.error(`TEST: ${provider} login error =>`, err);
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center space-y-6 px-6 text-center">
      <h2 className="text-2xl font-semibold">Sign in to Waivlength</h2>

      {/* Google login available on all platforms */}
      <button
        onClick={() => handleLogin("google")}
        className="bg-white text-black border rounded-lg px-6 py-3 shadow hover:bg-gray-50"
      >
        Continue with Google
      </button>

      {/* Apple login only on iOS */}
      {platform === "ios" && (
        <button
          onClick={() => handleLogin("apple")}
          className="bg-black text-white rounded-lg px-6 py-3 shadow hover:bg-gray-800"
        >
          Continue with Apple
        </button>
      )}
    </div>
  );
}
