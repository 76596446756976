import React, { createContext, useState, useContext, useCallback } from "react";
import { MatrixClientContext } from "./MatrixClientContext";

export const UserDetailsContext = createContext();

const UserDetailsProvider = ({ children }) => {
  const { matrixClient } = useContext(MatrixClientContext);
  const [avatar, setAvatar] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  // Function to get authenticated media URL directly in context
  const getAuthenticatedMediaUrl = (mxcUrl) => {
    if (!mxcUrl || !mxcUrl.startsWith("mxc://")) return null;
    const serverName = mxcUrl.split("/")[2];
    const mediaId = mxcUrl.split("/")[3];
    const accessToken = matrixClient.getAccessToken();
    const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
    return `${baseUrl}/_matrix/client/v1/media/download/${serverName}/${mediaId}?access_token=${accessToken}`;
  };

  // Function to manually trigger a refresh
  const refresh = useCallback(() => {
    setRefreshKey((prev) => prev + 1);
  }, []);

  // Fetch profile data immediately if matrixClient is available
  const fetchProfileData = async () => {
    if (!matrixClient) return;
    try {
      const userId = matrixClient.getUserId();
      const profile = await matrixClient.getProfileInfo(userId);
      const avatarUrl = profile?.avatar_url
        ? getAuthenticatedMediaUrl(profile.avatar_url)
        : null;
      const fetchedDisplayName =
        profile?.displayname ||
        userId.split(":")[0].replace("@", "") ||
        "Loading";

      setAvatar(avatarUrl);
      setDisplayName(fetchedDisplayName);
    } catch {
      setAvatar(null);
      setDisplayName(null);
    }
  };

  // Load data immediately if matrixClient is available
  if (matrixClient && !displayName) {
    fetchProfileData();
  }

  // Re-fetch profile data on refreshKey update
  React.useEffect(() => {
    if (matrixClient) fetchProfileData();
  }, [refreshKey]);

  return (
    <UserDetailsContext.Provider value={{ avatar, displayName, refresh }}>
      {children}
    </UserDetailsContext.Provider>
  );
};

export default UserDetailsProvider;
