import React, { createContext, useState, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { useAccount, useDisconnect } from "wagmi";
import { web3login } from "../../client/action/auth";
import { logoutMatrixClient } from "../../util/matrixClientUtils";

export const AppConnectionContext = createContext();

const AppConnectionProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const baseUrl = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
  const jwtSecret = import.meta.env.VITE_WAIVLENGTH_JWT_SECRET;
  const localStorageKey = "waivlength_credentials";

  useEffect(() => {
    const authenticate = async () => {
      setIsLoading(true);
      const platform = Capacitor.getPlatform();
      setPlatform(platform);
      const storedCredentials = JSON.parse(
        localStorage.getItem(localStorageKey)
      );

      const hasValidCredentials =
        storedCredentials && Object.values(storedCredentials).every(Boolean);

      if (hasValidCredentials) {
        setCredentials(storedCredentials);
      } else if (platform === "web" && isConnected) {
        try {
          const authResult = await web3login(baseUrl, address, jwtSecret);
          const newCredentials = {
            ...authResult,
            baseUrl,
            walletAddress: address,
            biometrics: "disabled",
          };
          setCredentials(newCredentials);
          localStorage.setItem(localStorageKey, JSON.stringify(newCredentials));
        } catch (error) {
          console.error("Error during web3login:", error);
          handleLogout();
        }
      } else if (platform === "web" && !isConnected && isAuthenticated) {
        handleLogout();
        disconnect();
      } else {
        setIsAuthenticated(false);
      }

      setIsLoading(false);
    };

    authenticate();
  }, [isConnected, address]);

  const handleLogin = (newCredentials) => {
    localStorage.removeItem(localStorageKey);
    setCredentials(newCredentials);
    localStorage.setItem(localStorageKey, JSON.stringify(newCredentials));
  };

  const handleLogout = async (matrixClient) => {
    if (matrixClient) {
      await logoutMatrixClient(matrixClient);
    }

    setCredentials(null);
    setIsAuthenticated(false);
    localStorage.removeItem(localStorageKey);
  };

  return (
    <AppConnectionContext.Provider
      value={{
        isAuthenticated,
        credentials,
        platform,
        handleLogin,
        handleLogout,
        isLoading,
        setIsAuthenticated,
      }}
    >
      {children}
    </AppConnectionContext.Provider>
  );
};

export default AppConnectionProvider;
