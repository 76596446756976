// NotificationsContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import { MatrixClientContext } from "./MatrixClientContext";
import { RoomsListContext } from "./RoomsListContext";
import {
  isNotifEvent,
  isMutedRule,
  findMutedRule,
  doesRoomHaveUnread,
} from "../../util/notificationsUtils";
import { Capacitor } from "@capacitor/core";
import axios from "axios";

export const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const { matrixClient } = useContext(MatrixClientContext);
  const { roomsList } = useContext(RoomsListContext);
  const [roomIdToNoti, setRoomIdToNoti] = useState(new Map());
  const [spaceNotifications, setSpaceNotifications] = useState({ total: 0 });
  const [chatNotifications, setChatNotifications] = useState({ total: 0 });
  const [totalNotificationsCount, setTotalNotificationsCount] = useState();

  // Function to send total notifications
  const sendTotalNotifications = async (totalCount) => {
    const platform = Capacitor.getPlatform();

    // Skip notification sending if platform is 'web'
    if (platform === "web") {
      return;
    }
    const deviceToken = localStorage.getItem("devicePushKey");

    if (!deviceToken || typeof deviceToken !== "string") {
      console.error("Invalid device push key:", deviceToken);
      return;
    }

    const sygnalServer = import.meta.env.VITE_WAIVLENGTH_APP_BASE_URL;
    const appId =
      platform === "ios" ? "app.waivlength" : "app.waivlength.android";

    const notificationPayload = {
      notification: {
        devices: [
          {
            pushkey: deviceToken,
            app_id: appId,
            data: {
              body: "Silent Notification",
              total_count: totalCount,
            },
          },
        ],
      },
    };

    try {
      await axios.post(
        `${sygnalServer}/_matrix/push/v1/notify`,
        notificationPayload
      );
    } catch (error) {
      console.error("Error sending notifications:", error);
    }
  };

  useEffect(() => {
    if (!matrixClient || !roomsList) return;

    const initNoti = () => {
      const initialNoti = new Map();

      // Here we include the rooms that are meant to be counted as chat (DMs and rooms).
      // (If your child rooms are not meant to be in the top-level list, this may already be taken care of.)
      const roomIds = new Set([
        ...roomsList.directMessages,
        ...roomsList.rooms,
        // Optionally: do NOT include spaces here because we calculate their badge from child rooms
        // ...roomsList.spaces,
      ]);

      roomIds.forEach((roomId) => {
        const room = matrixClient.getRoom(roomId);
        if (room) {
          const total = room.getUnreadNotificationCount("total") || 0;
          if (total > 0) {
            initialNoti.set(roomId, { total, from: null });
          }
        }
      });

      setRoomIdToNoti(initialNoti);
    };

    initNoti();

    const handleRoomTimeline = (mEvent, room) => {
      if (!isNotifEvent(mEvent)) return;

      const liveEvents = room.getLiveTimeline().getEvents();
      const lastTimelineEvent = liveEvents[liveEvents.length - 1];
      if (lastTimelineEvent.getId() !== mEvent.getId()) return;
      if (mEvent.getSender() === matrixClient.getUserId()) return;

      const total = room.getUnreadNotificationCount("total") || 0;
      const notiType = getNotiType(room.roomId);
      if (notiType === "mute") {
        deleteNoti(room.roomId);
        return;
      }
      setNoti(room.roomId, total);
    };

    const handleAccountData = (mEvent, oldMEvent) => {
      if (mEvent.getType() === "m.push_rules") {
        const override = mEvent?.getContent()?.global?.override;
        const oldOverride = oldMEvent?.getContent()?.global?.override;
        if (!override || !oldOverride) return;

        const isMuteToggled = (rule, otherOverride) => {
          const roomId = rule.rule_id;
          const room = matrixClient.getRoom(roomId);
          if (room === null) return false;
          const isMuted = isMutedRule(rule);
          if (!isMuted) return false;
          const isOtherMuted = findMutedRule(otherOverride, roomId);
          if (isOtherMuted) return false;
          return true;
        };

        const mutedRules = override.filter((rule) =>
          isMuteToggled(rule, oldOverride)
        );
        const unMutedRules = oldOverride.filter((rule) =>
          isMuteToggled(rule, override)
        );

        mutedRules.forEach((rule) => {
          deleteNoti(rule.rule_id);
        });

        unMutedRules.forEach((rule) => {
          const room = matrixClient.getRoom(rule.rule_id);
          if (!doesRoomHaveUnread(room, matrixClient)) return;
          const total = room.getUnreadNotificationCount("total") || 0;
          setNoti(room.roomId, total);
        });
      }
    };

    const handleRoomReceipt = (mEvent, room) => {
      if (mEvent.getType() === "m.receipt") {
        const content = mEvent.getContent();
        const readEventId = Object.keys(content)[0];
        const readerUserId = Object.keys(content[readEventId]["m.read"])[0];
        if (readerUserId !== matrixClient.getUserId()) return;
        deleteNoti(room.roomId);
      }
    };

    const handleRoomMyMembership = (room, membership) => {
      if (membership === "leave" && hasNoti(room.roomId)) {
        deleteNoti(room.roomId);
      }
    };

    matrixClient.on("Room.timeline", handleRoomTimeline);
    matrixClient.on("accountData", handleAccountData);
    matrixClient.on("Room.receipt", handleRoomReceipt);
    matrixClient.on("Room.myMembership", handleRoomMyMembership);

    return () => {
      matrixClient.off("Room.timeline", handleRoomTimeline);
      matrixClient.off("accountData", handleAccountData);
      matrixClient.off("Room.receipt", handleRoomReceipt);
      matrixClient.off("Room.myMembership", handleRoomMyMembership);
    };
  }, [matrixClient, roomsList]);

  // **Modified notifications totals calculation**
  useEffect(() => {
    if (!roomsList) return;

    // 1. Build a set of all child room IDs (the ones that belong to spaces)
    const childRoomIds = new Set();
    roomsList.spaceChildrenMap.forEach((childRoomIdsSet) => {
      childRoomIdsSet.forEach((childRoomId) => childRoomIds.add(childRoomId));
    });

    // 2. Chat notifications should only come from direct messages and rooms,
    //    but exclude any rooms that are actually child rooms of a space.
    const chatRoomIds = new Set([
      ...roomsList.directMessages,
      ...roomsList.rooms,
    ]);
    childRoomIds.forEach((childId) => chatRoomIds.delete(childId));

    let chatTotal = 0;
    chatRoomIds.forEach((roomId) => {
      const noti = roomIdToNoti.get(roomId);
      if (noti) {
        chatTotal += noti.total;
      }
    });
    setChatNotifications({ total: chatTotal });

    // 3. Space notifications: sum the unread count of all child rooms.
    let spaceTotal = 0;
    childRoomIds.forEach((roomId) => {
      const noti = roomIdToNoti.get(roomId);
      if (noti) {
        spaceTotal += noti.total;
      }
    });
    setSpaceNotifications({ total: spaceTotal });

    // 4. Include the sizes of invited rooms
    const invitedSizes =
      roomsList.invitedRooms.size +
      roomsList.invitedSpaces.size +
      roomsList.invitedDirectMessages.size;
    const totalCount = chatTotal + spaceTotal + invitedSizes;
    setTotalNotificationsCount(totalCount);
  }, [roomIdToNoti, roomsList]);

  // Send total notifications whenever the count changes
  useEffect(() => {
    if (typeof totalNotificationsCount !== "undefined") {
      sendTotalNotifications(totalNotificationsCount);
    }
  }, [totalNotificationsCount]);

  const setNoti = (roomId, total) => {
    setRoomIdToNoti((prev) => {
      const newMap = new Map(prev);
      newMap.set(roomId, { total, from: null });
      return newMap;
    });
  };

  const deleteNoti = (roomId) => {
    setRoomIdToNoti((prev) => {
      const newMap = new Map(prev);
      newMap.delete(roomId);
      return newMap;
    });
  };

  // Helper functions
  const getNoti = (roomId) => {
    return roomIdToNoti.get(roomId) || { total: 0, from: null };
  };

  const getTotalNoti = (roomId) => {
    const { total } = getNoti(roomId);
    return total;
  };

  const hasNoti = (roomId) => {
    return roomIdToNoti.has(roomId);
  };

  // Internal function; no need to export
  const getNotiType = (roomId) => {
    let pushRule;
    try {
      pushRule = matrixClient.getRoomPushRule("global", roomId);
    } catch {
      pushRule = undefined;
    }

    if (pushRule === undefined) {
      const overrideRules = matrixClient
        .getAccountData("m.push_rules")
        ?.getContent()?.global?.override;
      if (overrideRules === undefined) return "default";

      const isMuted = findMutedRule(overrideRules, roomId);

      return isMuted ? "mute" : "default";
    }
    if (pushRule.actions[0] === "notify") return "all_messages";
    return "mentions_and_keywords";
  };

  const getSpaceNotifications = (spaceId) => {
    if (!roomsList || !roomsList.spaceChildrenMap) return 0;

    // Get child room IDs or default to an empty array
    const childRoomIds = Array.from(
      roomsList.spaceChildrenMap.get(spaceId) || []
    );

    // Sum up notifications for child rooms
    return childRoomIds.reduce((total, roomId) => {
      const noti = roomIdToNoti.get(roomId);
      return total + (noti ? noti.total : 0);
    }, 0);
  };

  return (
    <NotificationsContext.Provider
      value={{
        getNoti,
        getTotalNoti,
        hasNoti,
        chatNotifications,
        spaceNotifications,
        getNotiType,
        getSpaceNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
