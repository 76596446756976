import React from "react";
import AppConnectionProvider from "./AppConnectionContext";
import { Web3ModalProvider } from "./Web3ModalContext";
import AppStateProvider from "./AppStateContext";
import AppGlobalStateProvider from "./AppGlobalStateContext";
import NotificationsProvider from "./NotificationsContext";
import { RoomSelectProvider } from "./RoomSelectContext";
import { MessagingProvider } from "./MessagingProviderContext";
import { MessagingOptionsProvider } from "./MessagingOptionsContext";
import RoomsListProvider from "./RoomsListContext";
import RoomsDataProvider from "./RoomsDataContext";
import RoomsStateProvider from "./RoomsStateContext";
import UserDetailsProvider from "./UserDetailsContext";
import BlockchainProvider from "./BlockchainContext";
import { MatrixClientProvider } from "./MatrixClientContext";

export function Providers({ children }) {
  return (
    // <Web3ModalProvider>
    //   <BlockchainProvider>
    //     <AppGlobalStateProvider>
    //       <AppConnectionProvider>
    //         <MatrixClientProvider>
    //           <RoomsListProvider>
    //             <NotificationsProvider>
    //               <RoomsDataProvider>
    //                 <RoomsStateProvider>
    //                   <AppStateProvider>
    //                     <RoomSelectProvider>
    //                       <MessagingProvider>
    //                         <MessagingOptionsProvider>
    //                           <UserDetailsProvider>
    { children }
    //                           </UserDetailsProvider>
    //                         </MessagingOptionsProvider>
    //                       </MessagingProvider>
    //                     </RoomSelectProvider>
    //                   </AppStateProvider>
    //                 </RoomsStateProvider>
    //               </RoomsDataProvider>
    //             </NotificationsProvider>
    //           </RoomsListProvider>
    //         </MatrixClientProvider>
    //       </AppConnectionProvider>
    //     </AppGlobalStateProvider>
    //   </BlockchainProvider>
    // </Web3ModalProvider>
  );
}
