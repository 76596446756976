import React, { createContext, useState, useContext, useEffect } from "react";
import {
  fetchUserRooms,
  categorizeRoom,
  mapChildRooms,
  getMDirects,
  isDirectMessageInvite,
  isMEventSpaceChild,
} from "../../util/roomsListUtils";
import { MatrixClientContext } from "./MatrixClientContext";

export const RoomsListContext = createContext();

const RoomsListProvider = ({ children }) => {
  const { matrixClient } = useContext(MatrixClientContext);

  const [roomsList, setRoomsList] = useState({
    directMessages: new Set(),
    rooms: new Set(),
    spaces: new Set(),
    invitedDirectMessages: new Set(),
    invitedRooms: new Set(),
    invitedSpaces: new Set(),
    spaceChildrenMap: new Map(),
  });

  const initializeRoomsList = async () => {
    if (!matrixClient) {
      return;
    }

    const categorizedState = {
      directMessages: new Set(),
      rooms: new Set(),
      spaces: new Set(),
      invitedDirectMessages: new Set(),
      invitedRooms: new Set(),
      invitedSpaces: new Set(),
      spaceChildrenMap: new Map(),
    };

    try {
      const allRooms = await fetchUserRooms(matrixClient);

      await Promise.all(
        allRooms.map((room) =>
          categorizeRoom(room, matrixClient, categorizedState)
        )
      );

      await Promise.all(
        Array.from(categorizedState.spaces).map(async (spaceId) => {
          await mapChildRooms(
            spaceId,
            matrixClient,
            categorizedState.spaceChildrenMap
          );
        })
      );

      setRoomsList(categorizedState);
    } catch (error) {
      console.error("Error initializing RoomsList:", error);
      throw error; // Let AppStateProvider handle this error
    }
  };

  useEffect(() => {
    if (!matrixClient) {
      return;
    }

    const handleAccountData = (event) => {
      if (event.getType() !== "m.direct") return;

      const latestMDirects = getMDirects(matrixClient);

      setRoomsList((prevState) => {
        const currentMDirects = prevState.directMessages;
        const addedRooms = new Set(
          [...latestMDirects].filter((x) => !currentMDirects.has(x))
        );
        const removedRooms = new Set(
          [...currentMDirects].filter((x) => !latestMDirects.has(x))
        );

        const newDirectMessages = new Set(prevState.directMessages);
        const newRooms = new Set(prevState.rooms);

        addedRooms.forEach((roomId) => {
          const room = matrixClient.getRoom(roomId);
          if (room && room.getMyMembership() === "join") {
            newDirectMessages.add(roomId);
            newRooms.delete(roomId);
          }
        });

        removedRooms.forEach((roomId) => {
          const room = matrixClient.getRoom(roomId);
          if (room && room.getMyMembership() === "join") {
            newDirectMessages.delete(roomId);
            newRooms.add(roomId);
          }
        });

        return {
          ...prevState,
          directMessages: newDirectMessages,
          rooms: newRooms,
        };
      });
    };

    const handleRoomStateEvents = (event, state) => {
      if (event.getType() === "m.space.child") {
        const roomId = event.getRoomId();
        const childId = event.getStateKey();

        if (isMEventSpaceChild(event)) {
          setRoomsList((prevState) => {
            const newSpaceChildrenMap = new Map(prevState.spaceChildrenMap);

            if (!newSpaceChildrenMap.has(roomId)) {
              newSpaceChildrenMap.set(roomId, new Set());
            }
            const childrenSet = newSpaceChildrenMap.get(roomId);
            childrenSet.add(childId);

            return {
              ...prevState,
              spaceChildrenMap: newSpaceChildrenMap,
            };
          });
        } else {
          setRoomsList((prevState) => {
            const newSpaceChildrenMap = new Map(prevState.spaceChildrenMap);

            if (newSpaceChildrenMap.has(roomId)) {
              const childrenSet = newSpaceChildrenMap.get(roomId);
              childrenSet.delete(childId);
              if (childrenSet.size === 0) {
                newSpaceChildrenMap.delete(roomId);
              }
            }

            return {
              ...prevState,
              spaceChildrenMap: newSpaceChildrenMap,
            };
          });
        }
      }
    };

    const handleRoomMyMembership = (room, membership, prevMembership) => {
      const roomId = room.roomId;

      if (membership === "invite") {
        const isDMInvite = isDirectMessageInvite(room, matrixClient);

        setRoomsList((prevState) => {
          const newInvitedDirectMessages = new Set(
            prevState.invitedDirectMessages
          );
          const newInvitedRooms = new Set(prevState.invitedRooms);
          const newInvitedSpaces = new Set(prevState.invitedSpaces);

          if (isDMInvite) {
            newInvitedDirectMessages.add(roomId);
          } else if (room.isSpaceRoom()) {
            newInvitedSpaces.add(roomId);
          } else {
            newInvitedRooms.add(roomId);
          }

          return {
            ...prevState,
            invitedDirectMessages: newInvitedDirectMessages,
            invitedRooms: newInvitedRooms,
            invitedSpaces: newInvitedSpaces,
          };
        });
        return;
      }

      if (prevMembership === "invite") {
        setRoomsList((prevState) => {
          const newInvitedDirectMessages = new Set(
            prevState.invitedDirectMessages
          );
          const newInvitedRooms = new Set(prevState.invitedRooms);
          const newInvitedSpaces = new Set(prevState.invitedSpaces);

          newInvitedDirectMessages.delete(roomId);
          newInvitedRooms.delete(roomId);
          newInvitedSpaces.delete(roomId);

          return {
            ...prevState,
            invitedDirectMessages: newInvitedDirectMessages,
            invitedRooms: newInvitedRooms,
            invitedSpaces: newInvitedSpaces,
          };
        });
      }

      if (membership === "join") {
        const isDM = getMDirects(matrixClient).has(roomId);

        setRoomsList((prevState) => {
          const newDirectMessages = new Set(prevState.directMessages);
          const newRooms = new Set(prevState.rooms);
          const newSpaces = new Set(prevState.spaces);

          if (isDM) {
            newDirectMessages.add(roomId);
          } else if (room.isSpaceRoom()) {
            newSpaces.add(roomId);
          } else {
            newRooms.add(roomId);
          }

          return {
            ...prevState,
            directMessages: newDirectMessages,
            rooms: newRooms,
            spaces: newSpaces,
          };
        });

        return;
      }

      if (["leave", "kick", "ban"].includes(membership)) {
        setRoomsList((prevState) => {
          const newDirectMessages = new Set(prevState.directMessages);
          const newRooms = new Set(prevState.rooms);
          const newSpaces = new Set(prevState.spaces);

          newDirectMessages.delete(roomId);
          newRooms.delete(roomId);
          newSpaces.delete(roomId);

          return {
            ...prevState,
            directMessages: newDirectMessages,
            rooms: newRooms,
            spaces: newSpaces,
          };
        });
        return;
      }
    };

    matrixClient.on("accountData", handleAccountData);
    matrixClient.on("RoomState.events", handleRoomStateEvents);
    matrixClient.on("Room.myMembership", handleRoomMyMembership);

    return () => {
      matrixClient.off("accountData", handleAccountData);
      matrixClient.off("RoomState.events", handleRoomStateEvents);
      matrixClient.off("Room.myMembership", handleRoomMyMembership);
    };
  }, [matrixClient]);

  return (
    <RoomsListContext.Provider value={{ roomsList, initializeRoomsList }}>
      {children}
    </RoomsListContext.Provider>
  );
};

export default RoomsListProvider;
